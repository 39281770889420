var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('v-data-table',{attrs:{"item-key":"idOrderStock","show-select":_vm.showSelect,"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{staticClass:"pr-5",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand pr-3",on:{"click":function($event){return _vm.viewRecord(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye pt-5"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.detail"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.download_PDF"))+" ")])])]}},{key:`item.statusName`,fn:function({ item }){return [_c('ChipTableComponent',{attrs:{"status":item.status,"statusName":item.statusName}})]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"m-1",attrs:{"color":"green","dark":""}},[_vm._v(" Activo ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e(),(_vm.isButtonDetail)?_c('div',{staticClass:"row"},[(
        _vm.pickingStatus == 2
          ? _vm.withPermissionName(_vm.permissions.PIC_PIC_END_02)
          : _vm.withPermissionName(_vm.permissions.PIC_PIC_VALI_END_02)
      )?_c('div',{staticClass:"col-12 pt-10 text-right"},[_c('button',{class:_vm.pickingStatus == 2
            ? 'btn btn-outline-success'
            : 'btn btn-outline-warning',on:{"click":_vm.validateCheck}},[_c('i',{class:(_vm.pickingStatus == 2 ? 'ti-share-alt' : 'ti-check-box ') + ' mr-2'}),_vm._v(" "+_vm._s(_vm.pickingStatus == 2 ? _vm.$t("global.finish_picking") : _vm.$t("global.finish_picking_validation"))+" ")])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }